import styled from 'styled-components';

const ColorExample = styled.span`
    margin-right: 5px;
    display: inline-block;
    height: 10px;
    width: 10px;
`;

export default ColorExample;
